<script setup>
import { useAuthStore } from '../stores/auth'
import router from '../router'

const authStore = useAuthStore()

const logout = async () => {
    await authStore.logout()
    router.push({ path: '/' })
}
</script>
<template>
    <header>
        <nav>
            <router-link to="/my/home">
                <h1>Circle of Care</h1>
            </router-link>
            <ul>
                <li>
                    <a href="#">
                        Welcome, &nbsp;{{ authStore.user?.firstName }} &#9660;
                    </a>
                    <ul>
                        <li>
                            <router-link to="/my/home">Home</router-link>
                        </li>
                        <li v-if="authStore.user?.administrator">
                            <router-link to="/board">Board</router-link>
                        </li>
                        <li v-if="authStore.user?.administrator">
                            <router-link to="/my/settings/descriptions">
                                Settings
                            </router-link>
                        </li>
                        <li><a href="#" @click="logout">Logout</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </header>
</template>
<style scoped>
header {
    background-color: var(--color-coc-bg);
    padding: 0px;
    max-width: var(--max-width);
}
header h1 {
    margin: 0;
    padding: 0.5em;
    font-size: 1.5em;
    font-weight: 400;
}

a {
    margin: 0;
    padding: 0.5em;
    color: var(--color-text-light);
    text-decoration: none;
}

nav {
    margin-bottom: 0px;
}

nav ul li ul {
    background-color: var(--color-bg);
    cursor: pointer;
    top: 2rem;
    right: 0px;
}

nav ul li ul li:hover {
    filter: brightness(1.2);
}

nav ul li ul li a {
    border: none;
    box-shadow: none;
    cursor: pointer;
    color: var(--color-coc-blue);
}
</style>
