<script setup>
import { ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import router from '../router'

const authStore = useAuthStore()

if (authStore.isAuthenticated) {
    router.push('/my/home')
}

const email = ref('')
const password = ref('')
const message = ref('')

const login = async (email, password) => {
    try {
        await authStore.authenticate({ strategy: 'local', email, password })
        router.push('/board')
    } catch (error) {
        message.value = error.message
        console.log(error)
    }
}

const oAuthLogin = () => {
  window.location.href = '/oauth/microsoft'
}
</script>
<template>
    <header>
        <nav>
            <img
                decoding="async"
                width="656"
                height="164"
                src="https://i0.wp.com/www.circleofcarecmo.org/wp-content/uploads/2021/04/Circle-of-Care_Horizontal-Logo_Main.png?fit=656%2C164&amp;ssl=1"
                alt="circle of care logo" />
        </nav>
        <h1>In/Out Board</h1>
        <h2>Welcome</h2>
    </header>
    <section>
        <router-link v-if="authStore.isAuthenticated" to="/my/home">
            Home
        </router-link>
        <button v-else @click="oAuthLogin" @keyup.enter="oAuthLogin" type="button" class="o365-login">Login with Office 365</button>
    </section>
    <section class="login">
        <h3>Admin Use Login</h3>
        <div class="form-control">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="email" />
        </div>
        <div class="form-control">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" />
        </div>
        <button type="button" @click="login(email, password)" class="login-button">Login</button>
        <span class="message">{{ message }}</span>
    </section>
</template>
<style scoped>
header nav img {
    width: 100%;
    height: auto;
}
.login {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: 2rem auto;
    padding: 3rem 2rem;
    border: 1px solid var(--color-bg-secondary);
    width: 20rem;
    align-items: center;
    border-radius: 5px;
}
.login h3 {
    margin-top: -1rem;
    color: red;
}
.form-control {
    display: flex;
    flex-direction: row;
    row-gap: 0.5rem;
    position: relative;
}
label {
    position: absolute;
    top: -1.3rem;
    left: 0;
    font-size: 0.75rem;
    padding: 0 0.5rem;
}
.o365-login {
    background-color: var(--color-coc-blue);
    color: var(--color-text-light);
    border-color: var(--color-light-shadow);
    transform: scale(1.4);
}
.login-button {
  color: var(--color-text);
  background-color: var(--color-coc-yellow);
  border-color: var(--color-inactive);
}
.message {
    color: red;
}
</style>
