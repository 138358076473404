import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', () => {
  // eslint-disable-next-line no-undef
  const { api } = useFeathers()
  // eslint-disable-next-line no-undef
  const utils = useAuth({ api, servicePath: 'users' })
  utils.reAuthenticate()
  return { ...utils }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
