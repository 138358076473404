import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MyView from '../views/MyView.vue'
import { useAuthStore } from '../stores/auth'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/my',
    name: 'my',
    component: MyView,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('../views/SettingsView.vue'),
        children: [
          {
            path: 'descriptions',
            name: 'descriptions',
            component: () => import('../views/DescriptionsView.vue'),
          },
          {
            path: 'cars',
            name: 'cars',
            component: () => import('../views/CarsView.vue'),
          },
          {
            path: 'users',
            name: 'users',
            component: () => import('../views/UsersView.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/board',
    name: 'board',
    component: () => import('../views/BoardView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  if (to.hash?.startsWith('#access_token')) {
    const token = to.hash.split('=')[1].split('&')[0]
    await authStore.authenticate({ strategy: 'jwt', accessToken: token })
    return next('/my/home')
  }

  if (to.path === '/') {
    return next()
  }

  await authStore.reAuthenticate()
  if (!authStore.user) {
    authStore.loginRedirect = to
    return next({ name: 'login' })
  }
  return next()
})

export default router
