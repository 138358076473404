<script setup>
import { RouterView } from 'vue-router'
import { useAuthStore } from './stores/auth'
const authStore = useAuthStore()
</script>

<template>
  <router-view v-if="authStore.isInitDone"/>
  <div v-else>Loading...</div>
</template>

<style scoped>
</style>
