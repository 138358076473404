import socketio from '@feathersjs/socketio-client'
import { createPiniaClient } from 'feathers-pinia'
import { createClient } from 'ioboard-cloud'
import io from 'socket.io-client'
import { pinia } from './modules/pinia'
const socket = io('wss://ioboard.circleofcarecmo.org', { transports: ['websocket'] })

const feathersClient = createClient(socketio(socket), { storage: window.localStorage })

export const api = createPiniaClient(feathersClient, {
  pinia,
  idField: '_id',
  // optional
  ssr: false,
  syncWithStorage: true,
  storage: window.localStorage,
  whitelist: [],
  paramsForServer: [],
  skipGetIfExists: true,
  customSiftOperators: {},
  setupInstance (data) {
    return data
  },
  customizeStore (defaultStore) {
    return defaultStore
  },
  services: {
    cars: {
      idField: '_id',
      // optional
      ssr: false,
      syncWithStorage: true,
      storage: window.localStorage,
      skipGetIfExists: true,
    },
    descriptions: {
      idField: '_id',
      // optional
      ssr: false,
      syncWithStorage: true,
      storage: window.localStorage,
      skipGetIfExists: true,
    },
  }
})
